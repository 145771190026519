<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <v-card outlined>
          <h2 class="mx-4 my-5">{{ $t("backend.guide.menuTitle") }}</h2>
          <v-list dense nav>
            <template v-for="(navItem, index) in navItems">
              <v-list-group
                v-if="navItem.children"
                :key="`navItem-${index}`"
                :prepend-icon="navItem.icon"
                class="mb-2"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ navItem.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="(subItem, i) in navItem.children"
                  :key="`subItem-${i}`"
                  :to="subItem.to"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ subItem.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-else
                :key="`navItem-${index}`"
                link
                :to="navItem.to"
              >
                <v-list-item-icon>
                  <v-icon>{{ navItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ navItem.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="10">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GuideLayout",
  data() {
    return {
      navItems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          to: "/backend/guide/dashboard",
        },
        // {
        //   title: "iEC",
        //   icon: "mdi-tooltip-text",
        //   to: "/backend/guide/iEC",
        //   children: [
        //     {
        //       title: "iEC Tool",
        //       to: "/backend/guide/iec-tool",
        //     },
        //     {
        //       title: "iEC Lexikon",
        //       to: "/backend/guide/iec-wiki",
        //     },
        //   ],
        // },
        {
          title: "Befunde",
          icon: "mdi-text-box",
          to: "/backend/guide/reports",
          children: [
            {
              title: "Übersicht",
              to: "/backend/guide/reports/overview",
            },
            // {
            //   title: "Befund anzeigen",
            //   to: "/backend/guide/reports/overview",
            // },
            // {
            //   title: "Befundinterpretation anfragen",
            //   to: "/backend/guide/reports/interpretation",
            // },
            {
              title: "Befund kommentieren",
              to: "/backend/guide/reports/demands",
            },
          ],
        },
        {
          title: "Nachrichten",
          icon: "mdi-message",
          to: "/backend/guide/messages",
        },
        {
          title: "Tickets",
          icon: "mdi-ticket",
          to: "/backend/guide/tickets",
        },
        {
          title: "Account",
          icon: "mdi-account",
          to: "/backend/guide/account",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
