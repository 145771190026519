<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card outlined class="pa-5">
        <v-card-title class="pt-0 px-0">
          {{ $t("backend.dashboard.lastReports") }}
        </v-card-title>
        <v-card-subtitle class="px-0">
          {{ $t("backend.dashboard.lastReportsMessage") }}
        </v-card-subtitle>
        <v-sheet outlined rounded>
          <v-data-table
            class="table-hover"
            :headers="tableHeadersReports"
            :items="reports"
            item-key="uuid"
            hide-default-footer
            :loading="loadingBefunde"
            :no-data-text="$t('backend.reports.noResults')"
            @click:row="downloadReport($event)"
          >
            <template v-slot:item.befund_id="{ item }">
              <span v-if="!item.read_at" class="font-weight-bold">{{
                item.befund_id
              }}</span>
              <span v-else>{{ item.befund_id }}</span>
            </template>
            <template v-slot:item.patient_data="{ item }">
              <span v-if="!item.read_at" class="font-weight-bold">{{
                item.patient_data
              }}</span>
              <span v-else>{{ item.patient_data }}</span>
            </template>
            <template v-slot:item.created_at="{ item }">
              <span v-if="!item.read_at" class="font-weight-bold">{{
                formatDate(item.created_at)
              }}</span>
              <span v-else>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-tooltip top close-delay="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-if="item.read_at" v-on="on" v-bind="attrs"
                    >mdi-eye-check</v-icon
                  >
                  <v-icon v-else v-on="on" v-bind="attrs"
                    >mdi-eye-remove</v-icon
                  >
                </template>
                <span v-if="item.read_at">{{ formatDate(item.read_at) }}</span>
                <span v-else>{{ $t("backend.reports.filters.unread") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-sheet>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card outlined class="pa-5">
        <v-card-title class="pt-0 px-0">
          {{ $t("backend.dashboard.reportDemands") }}
        </v-card-title>
        <v-card-subtitle class="px-0">
          {{ $t("backend.dashboard.reportDemandsMessage") }}
        </v-card-subtitle>
        <v-sheet outlined rounded>
          <v-data-table
            class="table-hover"
            :headers="tableHeaders"
            :items="reportDemands"
            hide-default-footer
            :loading="loadingDemands"
            :no-data-text="$t('backend.reports.demands.noResults')"
            @click:row="showSingleDemand"
          >
            <template v-slot:item.created_at="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.befundId="{ item }">
              <span v-if="item.report">
                {{ item.report.befund_id }}
              </span>
              <span v-else class="error--text">
                {{ $t("backend.reports.demands.reportDeleted") }}
              </span>
            </template>
            <template v-slot:item.patient_data="{ item }">
              <span v-if="item.report">
                {{ item.report.patient_data }}
              </span>
              <span v-else class="error--text">
                {{ $t("backend.reports.demands.reportDeleted") }}
              </span>
            </template>
          </v-data-table>
        </v-sheet>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { DateTime } from "luxon";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "DashboardArzt",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    reports: [],
    reportDemands: [],
    loadingBefunde: false,
    loadingDemands: false,
  }),
  computed: {
    authUser() {
      return this.$store.state.User.authUser;
    },
    tableHeaders() {
      return [
        {
          text: this.$t("backend.dashboard.reportDemandsTableHeaders.created"),
          value: "created_at",
          sortable: false,
        },
        {
          text: this.$t("backend.dashboard.reportDemandsTableHeaders.refNo"),
          value: "befundId",
          sortable: false,
        },
        {
          text: this.$t("backend.dashboard.reportDemandsTableHeaders.subject"),
          value: "subject",
          sortable: false,
        },
        {
          text: this.$t("backend.dashboard.reportDemandsTableHeaders.patient"),
          value: "patient_data",
          sortable: false,
        },
      ];
    },
    tableHeadersReports() {
      return [
        {
          text: this.$t("backend.dashboard.reportsTableHeaders.refNo"),
          value: "befund_id",
        },
        {
          text: this.$t("backend.dashboard.reportsTableHeaders.patient"),
          value: "patient_data",
        },
        {
          text: this.$t("backend.dashboard.reportsTableHeaders.uploaded"),
          value: "created_at",
        },
        {
          text: this.$t("backend.dashboard.reportsTableHeaders.status"),
          value: "status",
          sortable: false,
        },
      ];
    },
  },
  async mounted() {
    await this.fetchReports();
    await this.fetchReportDemands();
  },
  methods: {
    async fetchReports() {
      this.loadingBefunde = true;
      await ApiService.fetchReports(null)
        .then((res) => {
          this.reports = res.data.data;
        })
        .finally(() => {
          this.loadingBefunde = false;
        });
    },
    async downloadReport(report) {
      this.loadingReport = true;
      await ApiService.showReport(report.id)
        .then(async (res) => {
          const link = document.createElement("a");
          link.href = "data:application/pdf;base64," + res.data.filecontent;
          link.download = res.data.filename;
          document.body.appendChild(link);
          link.click();
          link.remove();

          await this.fetchReports();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            message: "Befund konnte nicht geladen werden:",
            error: getError(err),
          };

          this.$store.commit("Notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingReport = false;
        });
    },
    async fetchReportDemands() {
      this.loadingDemands = true;
      await ApiService.fetchReportDemands()
        .then((res) => {
          this.reportDemands = res.data.collection;
        })
        .finally(() => {
          this.loadingDemands = false;
        });
    },
    showSingleDemand(item) {
      this.$router.push({
        name: "befundnachfragen-list",
        query: { item: item.uuid },
      });
    },
    formatDate(date) {
      return DateTime.fromISO(date)
        .setLocale(this.$i18n.locale)
        .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
  },
};
</script>

<style scoped></style>
